/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import 'node_modules/swiper/swiper';
@import '@ionic/angular/css/ionic-swiper';

ion-toolbar, .inner-scroll {

}

* {
  font-family: "objektiv-mk2", sans-serif;
  color: #333
}

.bg-gray {
  //background: #F7F3F3;
}

.ifa-select {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .16);
}

ion-input {
  input {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-azzurro {
  background: #33ADFF;
}

.color-blu {
  color: #00538C !important;
}

.box {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .16);
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 15px;

  &.bg-red {
    background: #E26D5C;
    color: #fff
  }

  &.bg-gray {
    background: #DDE1E6;
    padding: 30px;
    overflow: hidden;

    img {
      margin-bottom: -7px;
    }
  }

  &.bg-cover {
    padding: 30px 25px !important;
    font-weight: 800;
  }

  &.home {
    padding: 30px 15px;
    margin-bottom: 30px
  }
}

.ifa-input {
  border: none;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .16);
  border-radius: 15px;
  padding: 13px 16px !important;
  display: block;
  width: 100%;
  background: #fff;

  &.big {
    font-weight: bold;
    font-size: 2rem;
    box-shadow: none;
    padding: 0;
    outline: none;
  }
}

.ifa-button {
  display: inline-block;
  background: #00538C;
  --background: #00538C;
  --box-shadow: none;
  color: #fff !important;
  text-align: center;
  --padding-bottom: 20px;
  --padding-top: 20px;
  --padding-start: 40px;
  --padding-end: 40px;
  border-radius: 50px !important;
  border: none;
  overflow: hidden;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, .16);
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  text-transform: initial;
  white-space: normal;
  height: auto;

  &.dark {
    --background: #003152;
    background: #003152;
  }

  &.full {
    width: 100%;
  }

  &.azzurro {
    --background: #33ADFF;
    background: #33ADFF;
  }

  &.white {
    background: #fff;
    --background: #fff;
    color: #00538C !important;
  }
}


.radio {
  position: relative;
  padding-left: 18px;

  label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #003152;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
  }

  [type=radio] {
    visibility: hidden;
    opacity: 0;
    position: absolute;

    &:checked + label:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 3.5px;
      transform: translateY(-50%);
      background: #33ADFF;
      height: .5rem;
      width: .5rem;
      border-radius: 50%;
    }
  }
}

.checkbox {
  position: relative;
  padding-right: 18px;

  label:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #fff;
    border: solid 1px #707070;
    border-radius: 2px;
    height: 1rem;
    width: 1rem;
  }

  [type=checkbox] {
    visibility: hidden;
    opacity: 0;
    position: absolute;

    &:checked + label:before {
      background: #003152;
    }
  }
}

label {
  display: block;
}

.form ion-row {

}

.color-white {
  color: #fff
}

.bg-blu {
  background: #00538C;
}

.mess {
  padding: 15px 40px 15px 25px;
  width: 95%;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}

.numbers {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .number {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &.selected {
      color: #fff;
      background: #00538C;
    }
  }
}

.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;

  .step-2 {
    .desc {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("./assets/step-center.png") !important;
      width: 240px !important;
      text-align: center;
    }
  }

  .step {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #33ADFF;
    position: relative;

    .desc {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 4px 10px 30px;
      background-image: url("./assets/step-bg.png");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 100% 100%;
      font-size: 12px;
      color: #fff;
      white-space: nowrap;
      width: auto;

      &.left {
        right: auto;
        left: 0;
        background-image: url("./assets/step-bg-2.png");
      }
    }
  }
}

div:not(.box).home {
  padding-top: 30px
}

.box-head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: bold;
  }
}

.font-22 {
  font-size: 22px;
}

.bolder, .font-black {
  font-weight: 900;
}

.color-70 {
  color: #707070;
}

.color-33 {
  color: #333;
}

.font-20 {
  font-size: 20px;
}

.font-60 {
  font-size: 60px;
}

.font-15 {
  font-size: 15px;
}

b {
  color: inherit;
}

ion-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.descrizione {
  line-height: 1;
  font-size: 13px;
  color: #707070;
}

.bold {
  font-weight: bold;
}

.ifa-button-form {
  background-color: #00538C;
  border-radius: 50px;
  padding: 10px 20px;
  color: #fff;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}


video {
  /* !important because component already sets height property */
  height: 100% !important;
}

.bottone {
  background-color: #00538C;
  color: #fff;
  padding: 10px 10px;
  border-radius: 60px;
}

.select-filter {
  width: 100%;
  border: none;
  border-radius: 60px;
  padding: 10px 5px;
}

.cerchio {
  background: #00538C;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-logout {
  background-color: #00538C;
  width: 100%;
  color: #fff;
  padding: 10px 10px;
  border-radius: 20px;
}

.bg-image {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}

.button-profile {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #00538C;
  color: #00538C;
  font-weight: bold;
  height: 100%;
  line-height: 14px;
}

.swiper-wrapper {
  align-items: center;
}

.fs_icon {
  font-size: 23px;
}
